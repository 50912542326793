import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommercialService } from '../../../../../services/commercial.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NewCustomerRequest } from '../../../../../payloads/requests/newCustomerRequest';
import { CheckCustomerRequest } from '../../../../../payloads/requests/checkCustomerRequest';
import { LoadingService } from '../../../../../services/loading.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TagModule } from 'primeng/tag';
import { AvatarModule } from 'primeng/avatar';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-customers',
  standalone: true,
  imports: [
    TagModule,
    AvatarModule,
    CommonModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    DialogModule,
    ScrollPanelModule,
    CheckboxModule,
    ReactiveFormsModule,
    MessagesModule
  ],
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.sass'
})
export class CustomersComponent implements OnInit, OnDestroy {

  customers: any[] = [];
  prospects: any[] = [];

  newCustomerDialogIsVisible: boolean = false;

  badRequestCustomerEmail: string = "";
  badRequestCustomerPhone: string = "";

  newCustomerForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    lastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private commercialService: CommercialService,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.commercialService.getAllCustomers()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.customers = response;
        }
      });

    this.commercialService.getAllProspects()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.prospects = response;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  identifyCustomer(index: number) {
    return index;
  }

  identifyProspect(index: number) {
    return index;
  }

  showNewCustomerDialog() {
    this.newCustomerForm.reset();
    this.newCustomerDialogIsVisible = true;
  }

  focusOutCustomerEmail() {
    this.badRequestCustomerEmail = "";
    const emailControl = this.newCustomerForm.get('email')!;
    if (emailControl.value && emailControl.valid) {
      const checkCustomerRequest: CheckCustomerRequest = new CheckCustomerRequest(null, emailControl.value!.toLowerCase());
      this.commercialService.checkCustomer(checkCustomerRequest, false)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            if (response) {
              this.newCustomerForm.patchValue({
                lastName: response.lastName,
                firstName: response.firstName,
                phone: response.phone
              });

              this.messageService.add({ severity: 'info', detail: 'Utilisateur trouvé. Si la correspondance n\'est pas bonne, vérifiez l\'email.', key: 'message-key-dialog-new-customer-commercial' });
            }
          }, error: error => {
            this.newCustomerForm.get('email')?.setValue('');
            this.newCustomerForm.get('legalNoticesAccepted')?.setValue(false);

            if (error.status === 400) {
              switch (error.field) {
                case "customerEmail":
                  this.badRequestCustomerEmail = error.description;
                  break;
                default:
                  this.messageService.clear();
                  this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-commercial' });
              }
            } else {
              this.messageService.clear();
              this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-commercial' });
            }
          }
        })
    }
  }

  onSubmitNewCustomer() {
    this.badRequestCustomerPhone = "";
    const request: NewCustomerRequest = new NewCustomerRequest(
      this.newCustomerForm.get('firstName')?.value!,
      this.newCustomerForm.get('lastName')?.value!,
      this.newCustomerForm.get('email')?.value!,
      this.newCustomerForm.get('phone')?.value!,
      null);
    this.commercialService.addCustomer(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.closeNewCustomerDialog();
          this.customers = [...this.customers, response];
          this.messageService.add({ severity: 'success', detail: 'Nouveau client créé', life: 5000 });
        }, error: error => {
          if (error.status === 400) {
            switch (error.field) {
              case "userPhone":
                this.badRequestCustomerPhone = error.description;
                break;
              default:
                this.messageService.clear();
                this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-commercial' });
            }
          } else {
            this.messageService.clear();
            this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-customer-commercial' });
          }
        }
      });
  }

  closeNewCustomerDialog() {
    this.newCustomerDialogIsVisible = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
