import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../services/loading.service';
import { NewCompanyRequest } from '../../../../payloads/requests/newCompanyRequest';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Router } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-companies',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    DialogModule,
    ReactiveFormsModule,
    CheckboxModule,
    ScrollPanelModule,
    MessagesModule,
    DropdownModule,
    InputNumberModule
  ],
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.sass'
})
export class CompaniesComponent implements OnInit, OnDestroy {

  companies: any[] = [];
  plans: any[] = [];

  hidePremiumPlanSettings: boolean = true;

  newCompanyDialogIsVisible: boolean = false;

  badRequestCompanyName: string = "";
  badRequestCompanyEmail: string = "";
  badRequestCompanyPhone: string = "";
  badRequestCompanySiren: string = "";
  badRequestUserEmail: string = "";
  badRequestUserPhone: string = "";

  newCompanyForm = this.formBuilder.group({
    planId: [0, Validators.required],
    premiumCustomPrice: 0,
    premiumCustomMaxUsers: 0,
    premiumCustomMaxBridgeTransastionPerMonth: 0,
    companyName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')])],
    companySiren: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{9}$')])],
    companyEmail: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    companyPhone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    adminEmail: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    adminLastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    adminFirstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    adminPhone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private superAdminService: SuperAdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.superAdminService.getAllCompanies()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companies = response;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  onRowSelectCompany(event: TableRowSelectEvent) {
    this.router.navigate([`portal/super-admin/company-details/${event.data.reference}`]);
  }

  showNewCompanyDialog() {
    this.newCompanyForm.reset();

    this.badRequestCompanyName = "";
    this.badRequestCompanyEmail = "";
    this.badRequestCompanyPhone = "";
    this.badRequestCompanySiren = "";
    this.badRequestUserEmail = "";
    this.badRequestUserPhone = "";

    this.newCompanyDialogIsVisible = true;

    this.superAdminService.getAllPlans()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.plans = response;
        }, error: error => {
          this.messageService.clear();
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-company' });
        }
      });
  }

  onChangePlan(event: DropdownChangeEvent) {
    if (event.value == 3) { // Premium plan
      this.hidePremiumPlanSettings = false;
      this.newCompanyForm.get('premiumCustomPrice')?.setValidators([Validators.required]);
      this.newCompanyForm.get('premiumCustomPrice')?.updateValueAndValidity();
      this.newCompanyForm.get('premiumCustomMaxUsers')?.setValidators([Validators.required]);
      this.newCompanyForm.get('premiumCustomMaxUsers')?.updateValueAndValidity();
      this.newCompanyForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.setValidators([Validators.required]);
      this.newCompanyForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.updateValueAndValidity();
    } else {
      this.hidePremiumPlanSettings = true;
      this.newCompanyForm.get('premiumCustomPrice')?.clearValidators();
      this.newCompanyForm.get('premiumCustomPrice')?.updateValueAndValidity();
      this.newCompanyForm.get('premiumCustomMaxUsers')?.clearValidators();
      this.newCompanyForm.get('premiumCustomMaxUsers')?.updateValueAndValidity();
      this.newCompanyForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.clearValidators();
      this.newCompanyForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.updateValueAndValidity();
    }
  }

  onSubmitNewCompany() {
    this.badRequestCompanyName = "";
    this.badRequestCompanyEmail = "";
    this.badRequestCompanyPhone = "";
    this.badRequestCompanySiren = "";
    this.badRequestUserEmail = "";
    this.badRequestUserPhone = "";

    const request: NewCompanyRequest = new NewCompanyRequest(
      this.newCompanyForm.get('companyName')?.value!,
      this.newCompanyForm.get('companySiren')?.value!,
      this.newCompanyForm.get('companyEmail')?.value!,
      this.newCompanyForm.get('companyPhone')?.value!,
      this.newCompanyForm.get('adminEmail')?.value!,
      this.newCompanyForm.get('adminLastName')?.value!,
      this.newCompanyForm.get('adminFirstName')?.value!,
      this.newCompanyForm.get('adminPhone')?.value!,
      this.newCompanyForm.get('planId')?.value!,
      this.newCompanyForm.get('premiumCustomPrice')?.value!,
      this.newCompanyForm.get('premiumCustomMaxUsers')?.value!,
      this.newCompanyForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.value!);
    this.superAdminService.createCompany(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.router.navigate([`portal/super-admin/company-details/${response.reference}`]);
        }, error: error => {
          if (error.status === 400) {
            switch (error.field) {
              case "companyName":
                this.badRequestCompanyName = error.description;
                break;
              case "companyEmail":
                this.badRequestCompanyEmail = error.description;
                break;
              case "companyPhone":
                this.badRequestCompanyPhone = error.description;
                break;
              case "companySiren":
                this.badRequestCompanySiren = error.description;
                break;
              case "userEmail":
                this.badRequestUserEmail = error.description;
                break;
              case "userPhone":
                this.badRequestUserPhone = error.description;
                break;
              default:
                this.messageService.clear();
                this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-company' });
            }
          } else {
            this.messageService.clear();
            this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-company' });
          }
        }
      })
  }

  closeNewCompanyDialog() {
    this.newCompanyDialogIsVisible = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
