export class NewCompanyRequest {

  companyName: string | null;
  companySiren: string | null;
  companyEmail: string | null;
  companyPhone: string | null;
  adminEmail: string | null;
  adminLastName: string | null;
  adminFirstName: string | null;
  adminPhone: string | null;
  planId: number | null;
  premiumCustomPrice: number | null;
  premiumCustomMaxUsers: number | null;
  premiumCustomMaxBridgeTransastionPerMonth: number | null;

  constructor(companyName: string | null, companySiren: string | null, companyEmail: string | null, companyPhone: string | null, adminEmail: string | null, adminLastName: string | null, adminFirstName: string | null, adminPhone: string | null, planId: number | null, premiumCustomPrice: number | null, premiumCustomMaxUsers: number | null, premiumCustomMaxBridgeTransastionPerMonth: number | null) {
    this.companyName = companyName;
    this.companySiren = companySiren;
    this.companyEmail = companyEmail;
    this.companyPhone = companyPhone;
    this.adminEmail = adminEmail;
    this.adminLastName = adminLastName;
    this.adminFirstName = adminFirstName;
    this.adminPhone = adminPhone;
    this.planId = planId;
    this.premiumCustomPrice = premiumCustomPrice;
    this.premiumCustomMaxUsers = premiumCustomMaxUsers;
    this.premiumCustomMaxBridgeTransastionPerMonth = premiumCustomMaxBridgeTransastionPerMonth;
  }
}