<div class="block">
    <div>
        <div class="text-lg font-bold text-900 mb-3 p-2">ENTREPRISES</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="companies.length === 0">
            <div class="text-xl font-medium text-900">Aucune entreprise trouvé...</div>
            <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewCompanyDialog()" [text]="true" />
        </div>

        <p-table #dt1 [value]="companies" sortField="name" [sortOrder]="1" selectionMode="single"
            (onRowSelect)="onRowSelectCompany($event)" [globalFilterFields]="['name', 'siren', 'email', 'phone']"
            [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true"
            [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="companies.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewCompanyDialog()" [text]="true" />
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">Nom<p-sortIcon field="name" /></th>
                    <th pSortableColumn="siren">SIREN<p-sortIcon field="siren" /></th>
                    <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                    <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company>
                <tr [pSelectableRow]="company">
                    <td>
                        <span class="md:hidden font-medium">Nom:&nbsp;</span>
                        <span>{{ company.name }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">SIREN:&nbsp;</span>
                        <span>{{ company.siren }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Email:&nbsp;</span>
                        <span>{{ company.email }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                        <span>{{ company.phone }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Ajouter une entreprise" [(visible)]="newCompanyDialogIsVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">

    <p-messages key="message-key-dialog-new-company" />

    <form [formGroup]="newCompanyForm" (ngSubmit)="onSubmitNewCompany()">
        <div class="flex flex-column gap-2 mb-5">
            <div class="my-3 text-500 font-medium">Définition de l'entreprise</div>
            <div class="flex flex-column gap-2 mb-2">
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="companyPlan">Offre</label>
                    <p-dropdown formControlName="planId" [options]="plans" optionLabel="label" optionValue="id"
                        placeholder="Choisir une offre" (onChange)="onChangePlan($event)"></p-dropdown>
                </div>
                <div [hidden]="hidePremiumPlanSettings">
                    <div class="flex flex-column md:flex-row md:justify-content-between gap-2">
                        <div class="flex flex-column gap-1 flex-grow-1">
                            <label class="text-xs font-medium" for="companyPlan">Quotas utilisateurs</label>
                            <p-inputNumber class="w-full" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                                placeholder="Quotas utilisateurs" formControlName="premiumCustomMaxUsers"
                                [useGrouping]="false" />
                        </div>
                        <div class="flex flex-column gap-1 flex-grow-1">
                            <label class="text-xs font-medium" for="companyPlan">Quotas transactions</label>
                            <p-inputNumber class="w-full" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                                placeholder="Quotas transactions"
                                formControlName="premiumCustomMaxBridgeTransastionPerMonth" [useGrouping]="false" />
                        </div>
                        <div class="flex flex-column gap-1 flex-grow-1">
                            <label class="text-xs font-medium" for="companyPlan">Prix/mois</label>
                            <p-inputNumber class="w-full" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
                                placeholder="Prix/mois" formControlName="premiumCustomPrice" mode="currency"
                                currency="EUR" locale="fr-FR" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="compayName">Nom d'entreprise</label>
                    <input id="companyName" placeholder="Nom d'entreprise" pInputText formControlName="companyName"
                        aria-describedby="companyname-edit">
                    <small class="text-red-600" id="companyname-edit"
                        [hidden]="!newCompanyForm.controls.companyName.errors?.['pattern']">
                        Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                    </small>
                    <small class="text-red-600" id="companyname-edit" [hidden]="!badRequestCompanyName.length">
                        {{ badRequestCompanyName }}
                    </small>
                </div>
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="companySiren">SIREN</label>
                    <input id="companySiren" placeholder="SIREN" pInputText formControlName="companySiren"
                        aria-describedby="siren-edit">
                    <small class="text-red-600" id="siren-edit"
                        [hidden]="!newCompanyForm.controls.companySiren.errors?.['pattern']">
                        Règles: 9 chiffres.
                    </small>
                    <small class="text-red-600" id="siren-edit" [hidden]="!badRequestCompanySiren.length">
                        {{ badRequestCompanySiren }}
                    </small>
                </div>
            </div>
            <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="companyEmail">Email</label>
                    <input id="companyEmail" placeholder="Email" pInputText formControlName="companyEmail"
                        aria-describedby="email-edit">
                    <small class="text-red-600" id="email-edit"
                        [hidden]="!newCompanyForm.controls.companyEmail.errors?.['pattern']">
                        Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                    </small>
                    <small class="text-red-600" id="email-edit" [hidden]="!badRequestCompanyEmail.length">
                        {{ badRequestCompanyEmail }}
                    </small>
                </div>
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="companyPhone">Téléphone</label>
                    <input id="companyPhone" placeholder="Téléphone" pInputText formControlName="companyPhone"
                        aria-describedby="phone-edit">
                    <small class="text-red-600" id="phone-edit"
                        [hidden]="!newCompanyForm.controls.companyPhone.errors?.['pattern']">
                        Règles: 10 chiffres.
                    </small>
                    <small class="text-red-600" id="phone-edit" [hidden]="!badRequestCompanyPhone.length">
                        {{ badRequestCompanyPhone }}
                    </small>
                </div>
            </div>

            <div class="my-3 text-500 font-medium">Définition de l'administrateur</div>
            <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="adminName">Nom</label>
                    <input id="adminName" placeholder="Nom" pInputText formControlName="adminLastName"
                        aria-describedby="adminLastName-edit">
                    <small class="text-red-600" id="adminLastName-edit"
                        [hidden]="!newCompanyForm.controls.adminLastName.errors?.['pattern']">
                        Règles: que des lettres, entre 2 et 30 caractères.
                    </small>
                </div>
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="adminFirstName">Prénom</label>
                    <input id="adminFirstName" placeholder="Prénom" pInputText formControlName="adminFirstName"
                        aria-describedby="adminFirstName-edit">
                    <small class="text-red-600" id="adminFirstName-edit"
                        [hidden]="!newCompanyForm.controls.adminFirstName.errors?.['pattern']">
                        Règles: que des lettres, entre 2 et 30 caractères.
                    </small>
                </div>
            </div>
            <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="adminEmail">Email</label>
                    <input id="adminEmail" placeholder="Email" pInputText formControlName="adminEmail"
                        aria-describedby="adminEmail-edit">
                    <small class="text-red-600" id="adminEmail-edit"
                        [hidden]="!newCompanyForm.controls.adminEmail.errors?.['pattern']">
                        Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                    </small>
                    <small class="text-red-600" id="adminEmail-edit" [hidden]="!badRequestUserEmail.length">
                        {{ badRequestUserEmail }}
                    </small>
                </div>
                <div class="flex flex-column gap-1 flex-grow-1">
                    <label class="text-xs font-medium" for="adminPhone">Téléphone</label>
                    <input id="adminPhone" placeholder="Téléphone" pInputText formControlName="adminPhone"
                        aria-describedby="adminPhone-edit">
                    <small class="text-red-600" id="adminPhone-edit"
                        [hidden]="!newCompanyForm.controls.adminPhone.errors?.['pattern']">
                        Règles: 10 chiffres.
                    </small>
                    <small class="text-red-600" id="adminPhone-edit" [hidden]="!badRequestUserPhone.length">
                        {{ badRequestUserPhone }}
                    </small>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-3">
            <div class="flex">
                <p-checkbox formControlName="legalNoticesAccepted" [binary]="true" />
            </div>
            <div class="flex">
                <p-scrollPanel [style]="{ width: '100%', height: '100px' }">
                    « Les informations recueillies par <span class="font-medium font-italic">LINKUPP</span> font l'objet
                    d'un
                    traitement informatique ayant pour finalité la gestion de notre fichier clients / prospects,
                    <span class="font-medium font-italic">LINKUPP</span> ayant un intérêt légitime à développer et
                    entretenir une
                    relation commerciale avec ses clients / prospects. Nous vous assurons qu'aucune donnée
                    ne sera communiquée, cédée ni revendue à des tiers. Les données seront conservées pour
                    une durée maximale de 3 ans après la fin de la relation commerciale, ou après votre
                    dernière demande. Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
                    modifiée et au Règlement Européen 2016/679 du 27 avril 2016 relatif à la protection des
                    personnes physiques à l'égard du traitement des données à caractère personnel, vous
                    disposez d'un droit d'accès et de rectification aux informations qui vous concernent ainsi que
                    d'un droit d'opposition, du droit à la limitation du traitement et à l'effacement dans le cadre
                    de la réglementation en vigueur. Vous pouvez exercer ces droits, il vous suffit d'envoyer un
                    email à cette adresse : <span class="font-medium font-italic">contact&#64;linkupp.fr</span>. Si vous
                    estimez, après nous avoir contactés, que vos
                    droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation
                    à la CNIL. »
                </p-scrollPanel>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" severity="danger" (onClick)="closeNewCompanyDialog()" />
            <p-button label="Enregistrer" icon="pi pi-save" type="submit" [loading]="getLoading()"
                [disabled]="!newCompanyForm.valid || getLoading()" />
        </div>
    </form>
</p-dialog>